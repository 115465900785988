import React, { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate, matchPath } from 'react-router-dom';
import Progress from '../components/progress/Progress';
import { useProgress } from '../context/ProgressContext';
import Homeicon from "../images/home";
import LampIcon from "../images/lamp";
import SettingsIcon from "../images/settings";
import HeartIcon from "../images/heart";
import { useAuth } from '../services/auth.provider';
import { CryptoService } from '../services/crypte.service';
import Navbar from "../components/Navbar";
import "../style/hidescrollbar.css";
import { useUserService } from '../services/userforms.service';
import LandingNav from '../components/LandingNav';
import LoginModal from "../components/modal/auth/LoginModal";

export default function LayoutNo({ children }) {
    const { currentPage, finalSectionProgress } = useProgress();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navbarRef = useRef(null);
    const [navbarHeight, setNavbarHeight] = useState(0);
    const [viewResult, setViewResult] = useState(false);
    const { getUserCalculate } = useUserService();

    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const currentPath = location.pathname;
    const showProgressBarPaths = ['/public', '/public/', '/public/moteur', '/public/moteur/'];
    const showProgressBar = showProgressBarPaths.includes(currentPath);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        const updateNavbarHeight = () => {
            if (navbarRef.current) {
                setNavbarHeight(navbarRef.current.offsetHeight);
            }
        };
        fetchData();
        updateNavbarHeight();
        window.addEventListener('resize', updateNavbarHeight);
        return () => {
            window.removeEventListener('resize', updateNavbarHeight);
        };
    }, []);


    const fetchData = async () => {
        if (isAuthenticated) {
            try {
                const userId = localStorage.getItem('user_id');
                const user = CryptoService.decryptData(userId);
                const response = await getUserCalculate(user);

                if (Array.isArray(response.data) && response.data.data.length === 0) {
                    setViewResult(false);
                } else {
                    setViewResult(true);
                }
            } catch (error) {
                console.error("Error fetching user calculate:", error);
                setViewResult(false);
            }
        }
    };

    const handleNavigation = () => {
        if (isAuthenticated) {
            const userId = localStorage.getItem('user_id');
            const encodedEncryptedUserId = encodeURIComponent(userId);
            navigate(`/saveditems/${encodedEncryptedUserId}`);
        } else {
            showModal()
        }
    };

    const isSavedItemsPage = matchPath("/saveditems/:userId", currentPath);

    const handleNavigationCheckTest = () => {
        if (viewResult) {
            navigate('/guidance');
        } else {
            navigate('/igikai');
        }
    };

    const showNavbarForPublic = showProgressBarPaths.includes(location.pathname);
    const hideAsideForPublicPaths = currentPath === '/public' || currentPath === '/public/moteur';

    const handleLoginSuccess = () => {
        window.location.reload();
    };

    return (
        <>
        <div className="flex flex-col min-h-screen bg-gray-50">
            {isAuthenticated || showNavbarForPublic ? (
                <Navbar onToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
            ) : (
                <LandingNav />
            )}
            {!hideAsideForPublicPaths && (
                <div className={`lg:fixed top-[${navbarHeight}px] left-0 w-64 h-full bg-white border-r border-gray-300 z-20`}>
                    <aside
                        className={`fixed inset-y-0 left-0 top-11 w-64 bg-white border-r border-gray-300 shadow-lg transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                        } lg:translate-x-0 lg:relative lg:w-64 lg:shadow-none lg:top-0 lg:z-0 z-30 flex flex-col`}
                    >
                        {/*<div className="relative mb-2">*/}
                        {/*    <button*/}
                        {/*        onClick={() => setIsSidebarOpen(false)}*/}
                        {/*        className="lg:hidden absolute top-1 right-4 p-2 text-gray-700"*/}
                        {/*    >*/}
                        {/*        <i className="pi pi-times-circle" style={{ fontSize: '1.5em' }}></i>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <nav className="py-5 px-4 w-full flex flex-col flex-1 lg:mt-5">
                            <ul className="flex-1">
                                <li>
                                    <button
                                        onClick={handleNavigationCheckTest}
                                        className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg 
                                            ${(currentPath === '/guidance' || currentPath === '/igikai') ? 'bg-[#E8EEF2] rounded-lg' : ''} 
                                            hover:bg-[#E8EEF2]`}
                                    >
                                        <LampIcon />
                                        Test d'orientation
                                    </button>
                                </li>

                                <li className="flex flex-col">
                                    {/* Explorer Button with Dropdown Icon */}
                                    <button
                                        onClick={toggleDropdown}
                                        type="button"
                                        className={`w-full py-3 px-2 flex items-center justify-between gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg hover:bg-[#E8EEF2] ${
                                            (isOpen && (currentPath === '/formation' || currentPath === '/metiers')) ||
                                            (!isOpen && (currentPath === '/formation' || currentPath === '/metiers')) ||
                                            (!isOpen && !(currentPath === '/igikai' || currentPath.startsWith('/saveditems') || currentPath === '/guidance'))
                                                ? 'bg-[#E8EEF2] rounded-lg'
                                                : ''
                                        }`}
                                    >
                                        <div className="flex items-center gap-x-1 ml-1">
                                            <Homeicon />
                                            Explorer
                                        </div>
                                        {/* Dropdown Icon */}
                                        <svg
                                            className={`w-4 h-4  transform transition-transform ${isOpen  ? 'rotate-180' : ''}`}
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>

                                    {/* Dropdown Menu Items */}
                                    {isOpen && (
                                        <div className="pl-3 mt-2 flex flex-col space-y-1">
                                            <Link
                                                to="/metiers"
                                                className={`block px-2 py-3 text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/metiers' ? ' bg-[#E8EEF2] ' : ''}`}
                                                onClick={() => setIsOpen(true)}
                                            >
                                                Métiers
                                            </Link>
                                            <Link
                                                to="/formation"
                                                className={`block py-3 px-2 text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/formation' ? ' bg-[#E8EEF2] ' : ''}`}
                                                onClick={() => setIsOpen(true)}
                                            >
                                                Formations
                                            </Link>
                                        </div>
                                    )}
                                </li>
                                <li>
                                    <button
                                        onClick={handleNavigation}
                                        className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg ${isSavedItemsPage ? 'bg-[#E8EEF2]' : ''} hover:bg-[#E8EEF2]`}
                                    >
                                        <HeartIcon />
                                        Enregistrés
                                    </button>
                                </li>

                            </ul>
                        </nav>
                    </aside>
                </div>
            )}

            {showProgressBar && (
                <div className={`fixed ${isAuthenticated ? 'md:left-64 md:mt-14' : 'mt-12'} w-full z-10`}>
                    <Progress isAuth={isAuthenticated} currentPart={currentPage} progress={finalSectionProgress} />
                </div>
            )}

            <div className={`flex flex-1 lg:ml-64 ${showProgressBar ? 'md:mt-16' : 'md:mt-12'}`}>
                <main className="flex-1  bg-[#FAFAFA]">
                    <div className="h-full w-full overflow-x-hidden overflow-y-hidden no-scrollbar">
                        {children}
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    <LoginModal visible={isModalVisible} onHide={hideModal} onLoginSuccess={handleLoginSuccess} />
    </>

);
}





// import React, { useEffect, useRef, useState } from 'react';
// import { Link, Outlet, useLocation, useNavigate, matchPath } from 'react-router-dom';
// import Progress from '../components/progress/Progress';
// import { useProgress } from '../context/ProgressContext';
// import Homeicon from "../images/home";
// import LampIcon from "../images/lamp";
// import SettingsIcon from "../images/settings";
// import HeartIcon from "../images/heart";
// import { useAuth } from '../services/auth.provider';
// import { CryptoService } from '../services/crypte.service';
// import Navbar from "../components/Navbar";
// import "../style/hidescrollbar.css";
// import { useUserService } from '../services/userforms.service';
// import LandingNav from '../components/LandingNav';
//
// export default function LayoutNo({ children }) {
//     const { currentPage, finalSectionProgress } = useProgress();
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const navbarRef = useRef(null);
//     const [navbarHeight, setNavbarHeight] = useState(0);
//     const [viewResult, setViewResult] = useState(false);
//     const { getUserCalculate } = useUserService();
//
//     const { isAuthenticated, logout } = useAuth();
//     const location = useLocation();
//     const navigate = useNavigate();
//
//     const currentPath = location.pathname;
//     const showProgressBarPaths = ['/public', '/public/', '/public/moteur', '/public/moteur/'];
//     const showProgressBar = showProgressBarPaths.includes(currentPath);
//     const [isOpen, setIsOpen] = useState(false);
//
//
//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };
//
//     const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
//
//     useEffect(() => {
//         const updateNavbarHeight = () => {
//             if (navbarRef.current) {
//                 setNavbarHeight(navbarRef.current.offsetHeight);
//             }
//         };
//         fetchData();
//         updateNavbarHeight();
//         window.addEventListener('resize', updateNavbarHeight);
//         return () => {
//             window.removeEventListener('resize', updateNavbarHeight);
//         };
//     }, []);
//
//
//     const fetchData = async () => {
//         if (isAuthenticated) {
//         try {
//             const userId = localStorage.getItem('user_id');
//             const user = CryptoService.decryptData(userId);
//             const response = await getUserCalculate(user);
//
//             if (Array.isArray(response.data) && response.data.data.length === 0) {
//                 setViewResult(false);
//             } else {
//                 setViewResult(true);
//             }
//         } catch (error) {
//             console.error("Error fetching user calculate:", error);
//             setViewResult(false);
//         }
//         }
//     };
//
//     const handleNavigation = () => {
//         if (isAuthenticated) {
//             const userId = localStorage.getItem('user_id');
//             const encodedEncryptedUserId = encodeURIComponent(userId);
//             navigate(`/saveditems/${encodedEncryptedUserId}`);
//         } else {
//             console.error('User ID not found in localStorage');
//         }
//     };
//
//     const isSavedItemsPage = matchPath("/saveditems/:userId", currentPath);
//
//     const handleNavigationCheckTest = () => {
//         if (viewResult) {
//             navigate('/guidance');
//         } else {
//             navigate('/igikai');
//         }
//     };
//
//     const showNavbarForPublic = showProgressBarPaths.includes(location.pathname);
//
//     return (
//         <div className="flex flex-col min-h-screen bg-gray-50">
//             {isAuthenticated || showNavbarForPublic ? (
//                 <Navbar onToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
//             ) : (
//                 <LandingNav />
//             )}
//             {isAuthenticated && (
//                 <div className={`lg:fixed top-[${navbarHeight}px] left-0 w-64 h-full bg-white border-r border-gray-300 z-20`}>
//                     <aside
//                         className={`fixed inset-y-0 left-0 top-11 w-64 bg-white border-r border-gray-300 shadow-lg transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
//                         } lg:translate-x-0 lg:relative lg:w-64 lg:shadow-none lg:top-0 lg:z-0 z-30 flex flex-col`}
//                     >
//
//                         <div className="relative mb-2">
//                             <button
//                                 onClick={() => setIsSidebarOpen(false)}
//                                 className="lg:hidden absolute top-1 right-4 p-2 text-gray-700"
//                             >
//                                 <i className="pi pi-times-circle" style={{ fontSize: '1.5em' }}></i>
//                             </button>
//                         </div>
//                         <nav className="py-5 px-4 w-full flex flex-col flex-1 lg:mt-5">
//                             <ul className="flex-1">
//                             <li>
//                                 <button
//                                         onClick={handleNavigationCheckTest}
//                                         className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg
//                                             ${(currentPath === '/guidance' || currentPath === '/igikai') ? 'bg-[#E8EEF2] rounded-lg' : ''}
//                                             hover:bg-[#E8EEF2]`}
//                                 >
//                                         <LampIcon />
//                                         Test d'orientation
//                                     </button>
//                                 </li>
//                                 {/*<li>*/}
//                                 {/*    <Link*/}
//                                 {/*        to="/metiers"*/}
//                                 {/*        className={`block px-1 text-black ${currentPath === '/metiers' ? 'bg-[#E8EEF2] rounded-lg ' : ''}`}*/}
//                                 {/*    >*/}
//                                 {/*        <button type="button" className="w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg hover:bg-[#E8EEF2]">*/}
//                                 {/*            <Homeicon />*/}
//                                 {/*            Explorer*/}
//                                 {/*        </button>*/}
//                                 {/*    </Link>*/}
//                                 {/*</li>*/}
//                                 <li className="flex flex-col">
//                                     {/* Explorer Button with Dropdown Icon */}
//                                     <button
//                                         onClick={toggleDropdown}
//                                         type="button"
//                                         className={`w-full py-3 px-2 flex items-center justify-between gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg hover:bg-[#E8EEF2] ${
//                                             (isOpen && (currentPath === '/formation' || currentPath === '/metiers')) ||
//                                             (!isOpen && (currentPath === '/formation' || currentPath === '/metiers')) ||
//                                             (!isOpen && !(currentPath === '/igikai' || currentPath.startsWith('/saveditems') || currentPath === '/guidance'))
//                                                 ? 'bg-[#E8EEF2] rounded-lg'
//                                                 : ''
//                                         }`}
//                                     >
//                                         <div className="flex items-center gap-x-1 ml-1">
//                                             <Homeicon />
//                                             Explorer
//                                         </div>
//                                         {/* Dropdown Icon */}
//                                         <svg
//                                             className={`w-4 h-4  transform transition-transform ${isOpen  ? 'rotate-180' : ''}`}
//                                             fill="none"
//                                             stroke="currentColor"
//                                             viewBox="0 0 24 24"
//                                             xmlns="http://www.w3.org/2000/svg"
//                                         >
//                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
//                                         </svg>
//                                     </button>
//
//                                     {/* Dropdown Menu Items */}
//                                     {isOpen && (
//                                         <div className="pl-3 mt-2 flex flex-col space-y-1">
//                                             <Link
//                                                 to="/metiers"
//                                                 className={`block px-2 py-3 text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/metiers' ? ' bg-[#E8EEF2] ' : ''}`}
//                                                 onClick={() => setIsOpen(true)}
//                                             >
//                                                 Métiers
//                                             </Link>
//                                             <Link
//                                                 to="/formation"
//                                                 className={`block py-3 px-2 text-black hover:bg-[#E8EEF2] rounded-lg ${currentPath === '/formation' ? ' bg-[#E8EEF2] ' : ''}`}
//                                                 onClick={() => setIsOpen(true)}
//                                             >
//                                                 Formations
//                                             </Link>
//                                         </div>
//                                     )}
//                                 </li>
//                                 <li>
//                                 <button
//                                         onClick={handleNavigation}
//                                         className={`w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg ${isSavedItemsPage ? 'bg-[#E8EEF2]' : ''} hover:bg-[#E8EEF2]`}
//                                     >
//                                         <HeartIcon />
//                                         Enregistrés
//                                     </button>
//                                 </li>
//
//                                 {/* <li>
//                                     <Link
//                                         to="/guidance"
//                                         className={`block px-1 text-black ${currentPath === '/guidance' ? 'bg-[#E8EEF2] rounded-lg  ' : ''}`}
//                                     >
//                                         <button type="button" className="w-full py-3 px-2 inline-flex items-center gap-x-1 font-inter text-[16px] max-w-14rem rounded-lg hover:bg-[#E8EEF2]">
//                                             <SettingsIcon />
//                                             Guidance
//                                         </button>
//                                     </Link>
//                                 </li> */}
//                             </ul>
//                         </nav>
//                     </aside>
//                 </div>
//             )}
//
//             {showProgressBar && (
//                 <div className={`fixed ${isAuthenticated ? 'md:left-64 md:mt-14' : 'mt-12'} w-full z-10`}>
//                     <Progress isAuth={isAuthenticated} currentPart={currentPage} progress={finalSectionProgress} />
//                 </div>
//             )}
//
//             <div className={`flex flex-1 ${isAuthenticated ? 'lg:ml-64' : ''} ${showProgressBar ? 'md:mt-16' : 'md:mt-12'}`}>
//                 <main className="flex-1  bg-[#FAFAFA]">
//                     <div className="h-full w-full overflow-x-hidden overflow-y-hidden no-scrollbar">
//                         {children}
//                         <Outlet />
//                     </div>
//                 </main>
//             </div>
//         </div>
//     );
// }
