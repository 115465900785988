import React, { useState } from 'react';
import { Avatar, Button, Menu, Dropdown } from 'antd';
import { DownOutlined, UpOutlined,MenuUnfoldOutlined,AlignRightOutlined } from '@ant-design/icons'; // Import UpOutlined for the "up" arrow
import logo from "../images/logo.svg";
import DefaultUser from "../images/user.svg";
import MentionsLegalesDialog from "../utils/MentionsLegale";
import ConditionsGeneralesDialog from "../utils/ConditionsGenerales";
import { Link } from 'react-router-dom';
import { useAuth } from "../services/auth.provider";
import { CryptoService } from '../services/crypte.service';

export default function Navbar({ onToggleSidebar }) {
    const [dropdownVisible, setDropdownVisible] = useState(false); // State to manage dropdown visibility
    const [openMentionsDialog, setOpenMentionsDialog] = useState(false);
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);
    const { isAuthenticated, logout } = useAuth();

    const userSurname = CryptoService.decryptData(localStorage.getItem('user_surname'));
    const userNameInitial = CryptoService.decryptData(localStorage.getItem('user_name'))?.charAt(0).toUpperCase();
    const userEmail = CryptoService.decryptData(localStorage.getItem('user_email'));

    const menu = (
        <Menu>
            <Menu.Item key="email" disabled>
                <span>{userEmail}</span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="mentions" onClick={() => setOpenMentionsDialog(true)}>
                Mentions légales
            </Menu.Item>
            <Menu.Item key="conditions" onClick={() => setOpenConditionsDialog(true)}>
                Conditions générales
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" onClick={logout}>
                Déconnexion
            </Menu.Item>
        </Menu>
    );

    const handleDropdownVisibleChange = (flag) => {
        setDropdownVisible(flag); // Update the state to track dropdown visibility
    };

    return (
        <nav className={`bg-white p-1 ${!isAuthenticated ? 'py-2' : ''} flex justify-between items-center fixed top-0 left-0 w-full border-b border-gray-300 z-50`}>
            <div className="flex items-center">
                {/* Button to open sidebar */}
                {/*{isAuthenticated && (*/}
                {/*    <Button*/}
                {/*        icon={<MenuUnfoldOutlined />}*/}
                {/*        className="lg:hidden ml-2"*/}
                {/*        onClick={onToggleSidebar}*/}
                {/*    />*/}
                {/*)}*/}

                {/* Logo */}
                <Link to="/landing">
                    <img
                        alt="logo"
                        src={logo}
                        width="170"
                        className="pl-3 cursor-pointer"
                    />
                </Link>
            </div>

            {isAuthenticated && (
                <div className="flex items-center space-x-2 relative">
                    <div className="hidden lg:flex items-center p-2 space-x-2">
                        <Dropdown
                            overlay={menu}
                            trigger={['click']}
                            placement="bottomRight"
                            onVisibleChange={handleDropdownVisibleChange} // Track dropdown visibility
                        >
                            <div className="flex items-center cursor-pointer">
                                <Avatar
                                    src={DefaultUser}
                                    className="border-1 border-blue-500 p-1 "
                                    style={{
                                        backgroundColor: '#C2EAFF',
                                        width: '30px',
                                        height: '30px',
                                    }}
                                />
                                <div className="ml-2 text-black">
                                    <p className="text-xs font-semibold m-0 leading-tight">
                                        {userSurname} {userNameInitial}
                                    </p>
                                </div>
                                {/* Conditionally render the UpOutlined or DownOutlined icon based on dropdown state */}
                                {dropdownVisible ? (
                                    <UpOutlined style={{ marginLeft: '8px', marginRight: '4px' }} />
                                ) : (
                                    <DownOutlined style={{ marginLeft: '8px', marginRight: '4px' }} />
                                )}
                            </div>
                        </Dropdown>
                    </div>

                    {/* For mobile */}
                    <div className="lg:hidden relative mt-1 flex mr-2">
                        <Dropdown
                            overlay={menu}
                            trigger={['click']}
                            placement="bottomRight"
                            onVisibleChange={handleDropdownVisibleChange} // Track dropdown visibility
                        >
                            <Avatar
                                src={DefaultUser}
                                className="border-1 border-blue-500 p-1 "
                                style={{
                                    backgroundColor: '#C2EAFF',
                                    width: '30px',
                                    height: '30px',
                                }}
                            />
                        </Dropdown>
                        {isAuthenticated && (
                            <Button
                                icon={<AlignRightOutlined />}
                                className="lg:hidden ml-2"
                                onClick={onToggleSidebar}
                            />
                        )}
                    </div>
                </div>
            )}

            {/* Dialog Components */}
            <MentionsLegalesDialog open={openMentionsDialog} onClose={() => setOpenMentionsDialog(false)} />
            <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />
        </nav>
    );
}
