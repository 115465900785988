import React, { useState, useEffect } from "react";
import LayoutNo from "../utils/LayoutNo";
import Bgimg from "../images/metierbg.png";
import BrushIcon from "../images/brush.svg";
import MetierCard from "../utils/MetierCard";
import { useMetierService } from "../services/metier.service";
import CustomSelectSearch from "../components/dropDown/CustomSelectSearch";
import ArrowDown from "../images/arrowDown";
import { useUserService } from "../services/userforms.service";
import LoginModal from "../components/modal/auth/LoginModal";
import { useItemsService } from "../services/ items.service";
import {useRef} from "react";
import { Toast } from 'primereact/toast';
import { CryptoService } from "../services/crypte.service";
import NodataFound from "../utils/NoDataFound";
import {Alert, notification, Pagination} from 'antd';
import {useAuth} from "../services/auth.provider";


export default function SearchSystemmetier() {
    const [visibleContent, setVisibleContent] = useState('Métiers');
    const [metiers, setMetiers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSalaireOpen, setIsSalaireOpen] = useState(false);
    const [studyLevels, setStudyLevels] = useState([]);
    const [salaryRanges, setSalaryRanges] = useState([]);
    const [businessSector, setBusinessSectors] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedStudyLevel, setSelectedStudyLevel] = useState('');
    const [selectedBusinessSector, setSelectedBusinessSector] = useState('');
    const [userId, setUserId] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [seniorPosition, setSeniorPosition] = useState(0);
    const [selectedSalaryRange, setSelectedSalaryRange] = useState('');
    const [showNoSelectionCard, setShowNoSelectionCard] = useState(true);
    const [keywords, setKeywords] = useState('');
    const [searchKeywords, setSearchKeywords] = useState('');
    const toast = useRef(null);
    const [clearFilters, setClearFilters] = useState(false);
    const { getStudiesLevel, getSalaireRange, getBusinessSector } = useUserService();
    const { searchProfessions } = useMetierService();
    const { saveProfession, deleteProfession } = useItemsService();
    const { isAuthenticated } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem('user_id');
        if (user) {
            const decryptedId = CryptoService.decryptData(user)
            setUserId(decryptedId)
        }
    }, []);


    useEffect(() => {
        // Retrieve and set the filter states from sessionStorage only once when the component mounts
        const savedStudyLevel = sessionStorage.getItem('selectedStudyLevel');
        const savedBusinessSector = sessionStorage.getItem('selectedBusinessSector');
        const savedSeniorPosition = sessionStorage.getItem('seniorPosition');
        const savedSalaryRange = sessionStorage.getItem('selectedSalaryRange');
        const savedKeywords = sessionStorage.getItem('searchKeywords');

        if (savedStudyLevel) setSelectedStudyLevel(savedStudyLevel);
        if (savedBusinessSector) setSelectedBusinessSector(savedBusinessSector);
        if (savedSeniorPosition) setSeniorPosition(Number(savedSeniorPosition));
        if (savedSalaryRange) setSelectedSalaryRange(savedSalaryRange);
        if (savedKeywords) setSearchKeywords(savedKeywords);
    }, []); // Empty dependency array so it runs only once on mount

    useEffect(() => {
        // Save filter states to sessionStorage whenever they change
        sessionStorage.setItem('selectedStudyLevel', selectedStudyLevel);
        sessionStorage.setItem('selectedBusinessSector', selectedBusinessSector);
        sessionStorage.setItem('seniorPosition', seniorPosition.toString());
        sessionStorage.setItem('selectedSalaryRange', selectedSalaryRange);
        sessionStorage.setItem('searchKeywords', searchKeywords);
    }, [selectedStudyLevel, selectedBusinessSector, seniorPosition, selectedSalaryRange, searchKeywords]);

    useEffect(() => {
        const fetchStudyLevels = async () => {
            try {
                const response = await getStudiesLevel(0, 1);
                setStudyLevels(response.data.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        const fetchSalaryRanges = async () => {
            try {
                const response = await getSalaireRange();
                setSalaryRanges(response.data.data);
            } catch (error) {
                console.error("Error fetching salary ranges:", error);
            }
        };

        const fetchBusinessSector = async () => {
            try {
                const response = await getBusinessSector();
                setBusinessSectors(response.data.data);
            } catch (error) {
                console.error("Error fetching business sectors:", error);
            }
        };

        fetchBusinessSector();
        fetchStudyLevels();
        fetchSalaryRanges();
    }, []);

    useEffect(() => {
        const fetchProfessions = async () => {
            if (
                (selectedStudyLevel && selectedBusinessSector && selectedSalaryRange && seniorPosition !== undefined) ||
                (seniorPosition && selectedSalaryRange && searchKeywords) ||
                (selectedStudyLevel && searchKeywords) ||
                (selectedBusinessSector && searchKeywords) ||
                searchKeywords
            ) {
                setShowNoSelectionCard(false);
                setLoading(true);
                setDataFetched(false);



                try {
                    const response = await searchProfessions(
                        selectedStudyLevel,
                        selectedBusinessSector,
                        seniorPosition,
                        selectedSalaryRange,
                        searchKeywords,
                        null,
                        userId,
                        currentPage,

                    );
                    console.log("Fetched data for page", currentPage, ":", response);

                    if (response.data && response.success === true) {
                        setMetiers(response.data.results || []);
                        setTotalPages(response.data.total_pages || 0);
                        setTotalCount(response.data.total_count || 0);

                        console.log("daata", currentPage, ":",  response.data.results);


                    } else {
                        setMetiers([]);
                    }
                } catch (error) {
                    console.error("Error fetching professions:", error);
                    setMetiers([]);
                } finally {
                    setLoading(false);
                    setDataFetched(true);

                }
            }
        };

        fetchProfessions();
    }, [selectedStudyLevel, selectedBusinessSector, seniorPosition, selectedSalaryRange, searchKeywords,currentPage]);

    const onPageChange = (page) => {
        setCurrentPage(page);
        window.scrollTo(0, 0);

    };


    const handleOptionClick = (option) => {
        setSelectedSalaryRange(option.salary_range_id);
        setSelectedOption(option.salary_range);
        // console.log("salaire",option.salary_range_id)
        setIsSalaireOpen(false);
    };

    const handleClearAll = () => {
        setSelectedStudyLevel('');
        setSelectedBusinessSector('');
        setSelectedSalaryRange('');
        setSeniorPosition(0);
        setMetiers([]);
        setSearchKeywords('');
        setKeywords('');
        setSelectedOption('');
        setClearFilters(true);
        setTimeout(() => {
            setClearFilters(false);
        }, 0);
        setShowNoSelectionCard(true);
        sessionStorage.removeItem('selectedStudyLevel');
        sessionStorage.removeItem('selectedBusinessSector');
        sessionStorage.removeItem('seniorPosition');
        sessionStorage.removeItem('selectedSalaryRange');
        sessionStorage.removeItem('searchKeywords');


    };


    const handleMissingStepsNotification = () => {
        notification.warning({
            message: 'Veuillez sélectionner trois critères ou utilisez des mots-clés pour effectuer une recherche.',
            placement: 'topRight',
        });
    };



    const handleSearch = () => {
        if (!isAuthenticated) {
            showModal();
            return;
        }

        if (keywords) {
            setSearchKeywords(keywords);
        } else {
            handleMissingStepsNotification();
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };
    const handleStudyLevelChange = (selectedOption) => {
        setSelectedStudyLevel(selectedOption ? selectedOption.studlevel_id : '');
    };

    const handleBusinessSectorChange = (selectedOption) => {
        setSelectedBusinessSector(selectedOption ? selectedOption.business_sect_id : '');
    };


    const toggleSalaireDropdown = () => setIsSalaireOpen(!isSalaireOpen);

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const handleSaveProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await saveProfession(userId, professionId);
            notification.success({
                message: 'Enregistré',
                placement: 'topRight',
            });
        } catch (error) {
            console.error('Error saving profession:', error);
            notification.error({
                message: 'Erreur',
                description: 'Une erreur est survenue lors de la sauvegarde de la profession.',
                placement: 'topRight',
            });
        }
    };

    const handleDeleteProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await deleteProfession(userId, professionId);
        } catch (error) {
            console.error('Error deleting profession:', error);
        }
    };
    const handleSeniorPositionToggle = () => {
        setSeniorPosition(prev => (prev === 0 ? 1 : 0));
    };

    const handleLoginSuccess = () => {
        window.location.reload();
    };
    return (
        <LayoutNo>
            <Toast ref={toast} position="top-center" />

            {/*<div className={`mt-6  md:mt-2 w-full ${!isAuthenticated ? "  md:px-24" : "p-1"}`}>*/}
            <div className={`mt-6  md:mt-2 w-full p-1 `}>

                <div
                    className="relative w-full md:h-36 h-24  rounded-xl bg-cover bg-center"
                    style={{ backgroundImage: `url(${Bgimg})` }}
                >


                    <div className="absolute md:mt-36 mt-24 inset-0 flex items-center justify-center px-1 ">

                        <div className="bg-white w-full  mx-3   rounded-lg shadow-lg flex items-center space-x-2 p-1">

                            <div className="flex-grow">
                                <input
                                    type="text"
                                    value={keywords}
                                    placeholder={sessionStorage.getItem('searchKeywords') || "Recherche par mot-clé..." }
                                    onKeyUp={handleKeyPress}
                                    onChange={(e) => setKeywords(e.target.value)}
                                    className="w-full p-1 text-sm text-gray-700 rounded-l focus:outline-none"

                                />
                            </div>

                            <button
                                type="button"
                                onClick={handleSearch}
                                className="hidden md:flex  justify-center items-center p-2  text-[16px] font-inter font-semibold  rounded-md border border-blue-500 bg-[#1681FF] text-white"
                            >
                                {/* Search Icon */}
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                                    <path d="M8.40307 14.0625C11.7636 14.0625 14.4878 11.4182 14.4878 8.15625C14.4878 4.89432 11.7636 2.25 8.40307 2.25C5.04258 2.25 2.31836 4.89432 2.31836 8.15625C2.31836 11.4182 5.04258 14.0625 8.40307 14.0625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.7051 12.333L16.2256 15.7502" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                                Recherche
                            </button>
                            <button
                                type="button"
                                onClick={handleSearch}
                                className="md:hidden   justify-center items-center p-2  text-[16px] font-inter font-semibold  rounded-md border border-blue-500 bg-[#1681FF] text-white"
                            >
                                {/* Search Icon */}
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <path d="M8.40307 14.0625C11.7636 14.0625 14.4878 11.4182 14.4878 8.15625C14.4878 4.89432 11.7636 2.25 8.40307 2.25C5.04258 2.25 2.31836 4.89432 2.31836 8.15625C2.31836 11.4182 5.04258 14.0625 8.40307 14.0625Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.7051 12.333L16.2256 15.7502" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>

                        </div>



                    </div>
                </div>


                <div className="p-1  ">
                    <div className="w-full flex flex-wrap lg:justify-between md:justify-center mt-5 items-center py-2 px-3 ">
                        <div className="flex flex-wrap justify-start gap-2 w-full lg:w-auto items-center" onClick={!isAuthenticated ? showModal : null}>
                            {/* Study Level Select */}
                            <div className="w-full sm:w-1/2 lg:w-auto">
                                <CustomSelectSearch
                                    options={studyLevels}
                                    optionKey="studlevel_id"
                                    optionLabel="study_level"
                                    placeholder="Niveau d'études"
                                    onChange={handleStudyLevelChange}
                                    clearFilters={clearFilters}
                                    width="180px"
                                />
                            </div>

                            {/* Business Sector Select */}
                            <div className="w-full sm:w-1/2 lg:w-auto">
                                <CustomSelectSearch
                                    options={businessSector}
                                    optionKey="business_sect_id"
                                    optionLabel="business_sect"
                                    placeholder="Secteur d'activité"
                                    onChange={handleBusinessSectorChange}
                                    clearFilters={clearFilters}
                                    width="180px"
                                />
                            </div>

                            {/* Salaire Button */}
                            <div className="w-full sm:w-1/2 lg:w-auto relative">
                                <div className="relative lg:w-56">
                                    <button
                                        className="flex justify-between items-center w-full px-4 py-2.5 border border-[#D0DBE7] bg-white rounded-lg focus:outline-none"
                                        onClick={!isAuthenticated ? showModal : toggleSalaireDropdown}
                                    >
                                        {selectedOption ? selectedOption : "Salaire"}
                                        <span className="ml-2"><ArrowDown /></span>
                                    </button>
                                    {isSalaireOpen && (
                                        <div className="absolute left-0 mt-2 w-full bg-white border border-[#D0DBE7] rounded-lg shadow-lg z-20">
                                            <div className="flex items-center justify-between px-2 py-3 border-b border-[#D0DBE7] bg-white rounded-t-md">
                                                <label className="text-black text-xs font-inter">Débutant</label>
                                                <div
                                                    className={`relative border-1 border-[#235C9B] w-14 h-4 flex items-center rounded-full p-1 cursor-pointer transition-colors ${seniorPosition === 0 ? 'bg-[#E5EDF6]' : 'bg-gray-300'}`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleSeniorPositionToggle();
                                                    }}
                                                >
                                                    <div
                                                        className={`bg-[#235C9B] w-4 h-3 rounded-full shadow-md transform transition-transform ${seniorPosition === 1 ? 'translate-x-8' : 'translate-x-0'}`}
                                                    ></div>
                                                </div>
                                                <label className="text-black text-xs font-inter">Expérimenté</label>
                                            </div>
                                            <ul className="max-h-32 overflow-y-auto py-2">
                                                {salaryRanges.map((range) => (
                                                    <li
                                                        key={range.salary_range_id}
                                                        onClick={() => handleOptionClick(range)}
                                                        className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 ${selectedOption && selectedOption.salary_range_id === range.salary_range_id ? 'bg-blue-50' : ''}`}
                                                    >
                                                        <span className="text-sm text-gray-800">{range.salary_range}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Clear All Button */}
                        <div className="flex justify-end mt-2 lg:mt-0 w-full lg:w-auto">
                            <button onClick={handleClearAll} className="flex gap-2 text-xs px-2 py-2 border-0 rounded-md hover:bg-gray-300">
                                <img src={BrushIcon} alt="edit" className="w-6 h-6" />
                                <span className="whitespace-nowrap p-1">Tout effacer</span>
                            </button>
                        </div>
                    </div>
                    {!loading && showNoSelectionCard && (

                        <div >
                            {/*<div className="justify-start">*/}
                            {/*    <p className="font-semibold font-poppins text-[28px] text-[#141943] p-1">*/}
                            {/*        Métiers populaires*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                            {/*<CarouselSlider/>*/}
                            {/*<CarouselSecteur/>*/}
                            {/*<CarouselFiliere/>*/}
                            <div className="w-full  flex justify-center">
                                <Alert
                                    message="Veuillez sélectionner trois critères ou utilisez des mots-clés pour effectuer une recherche."
                                    type="info"
                                    className="mx-3"
                                    showIcon
                                    style={{ width: "100%", marginBottom: '16px' }}
                                />
                            </div>
                        </div>
                    )}
                    {loading && (
                        <div className="grid-cols-1 flex justify-center  gap-2 mt-5 p-2">
                            <div
                                className="  animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                                role="status" aria-label="loading">
                            </div>
                        </div>
                    )}
                    {/*{ metiers.length === 0 && ((selectedStudyLevel && selectedBusinessSector && selectedSalaryRange)  || (selectedStudyLevel && searchKeywords) || (selectedBusinessSector && searchKeywords) || searchKeywords ) ? (*/}
                    {/*    <div className="mt-4">*/}
                    {/*        <NodataFound clearFilter={handleClearAll}/>*/}
                    {/*    </div>*/}
                    {/*) : null*/}
                    {/*    )}*/}
                {/*    {visibleContent === 'Métiers' ? (*/}
                {/*        <div>*/}
                {/*            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-2 md:px-2 px-4 ">*/}
                {/*                {metiers.map((metier) => (*/}
                {/*                    <MetierCard*/}
                {/*                        key={metier.profession_id}*/}
                {/*                        imgSrc={metier.pictact}*/}
                {/*                        smtitle={metier.business_sect}*/}
                {/*                        title={metier.profession}*/}
                {/*                        chips={[metier.required_stud_level]}*/}
                {/*                        frompriceDebutant={metier.salary_1}*/}
                {/*                        topriceDebutant={metier.salary_2}*/}
                {/*                        frompriceExp={metier.salary_3}*/}
                {/*                        topriceExp={metier.salary_4}*/}
                {/*                        professionId={metier.profession_id}*/}
                {/*                        saved={metier.saved} // Pass the save attribute*/}
                {/*                        saveProfession={handleSaveProfession}*/}
                {/*                        deleteProfession={handleDeleteProfession}*/}
                {/*                    />*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        </div>*/}

                {/*    ) : (*/}
                {/*        <div>Content for Formations</div>*/}
                {/*    )}*/}
                {/*</div>*/}
                    {!loading && dataFetched &&  metiers.length === 0 && (
                        (selectedStudyLevel || selectedBusinessSector || selectedSalaryRange || searchKeywords) ? (
                            <div className="mt-4">
                                <NodataFound clearFilter={handleClearAll} />
                            </div>
                        ) : null
                    )}
                    {visibleContent === 'Métiers' && !loading && dataFetched &&  metiers.length > 0 && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 mt-2 md:px-2 px-4">
                            {metiers.map((metier) => (
                                <MetierCard
                                    key={metier.profession_id}
                                    imgSrc={metier.pictact}
                                    smtitle={metier.business_sect}
                                    title={metier.profession}
                                    chips={[metier.required_stud_level]}
                                    frompriceDebutant={metier.salary_1}
                                    topriceDebutant={metier.salary_2}
                                    frompriceExp={metier.salary_3}
                                    topriceExp={metier.salary_4}
                                    professionId={metier.profession_id}
                                    saved={metier.saved}
                                    saveProfession={handleSaveProfession}
                                    deleteProfession={handleDeleteProfession}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <LoginModal visible={isModalVisible} onHide={hideModal} onLoginSuccess={handleLoginSuccess}  />
            </div>
            <div className="flex justify-center my-2">
                {!loading && metiers.length !== 0 && (
                        <Pagination
                            total={totalCount}
                            current={currentPage}
                            pageSize={16}
                            onChange={onPageChange}
                            showSizeChanger={false}
                            showTotal={(total) => ` ${total} Métiers`}
                        />

                )}

            </div>

        </LayoutNo>
    );
}
