import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Registration from "./components/Registration";
import Guidance from "./pages/Guidance";
import Environnement from "./pages/Environnement";
import Testing from './pages/Testing';
import SujetsPreferes from './pages/SujetsPreferes';
import SearchSystemFormation from "./pages/SearchSystemFormation";
import SavedItems from "./pages/SavedItems";
import MetierDetailPage from "./pages/MetierDetailPage";
import ErrorPage from './pages/ErrorPage';
import { ProgressProvider } from './context/ProgressContext';
import LandingPage from './pages/LandingPage';
import SearchSystemmetier from "./pages/SearchSystemMetier";
import QuiCeQueIkigai from "./pages/QuiCeQueIkigai";
import PrivateRoute from './utils/routes/PrivateRoute';
import { AuthProvider } from './services/auth.provider';
import Cgu from "./pages/cgu/CguPage";
import MentionsL from "./pages/cgu/MentionsL";
import FormationDetailPage from "./pages/FormationDetailPage";
import IsAccessibleRoute from './utils/routes/IsAccessibleRoute';
import PublicRoute from "./utils/routes/PublicRoute";

function App() {

  return (
    <AuthProvider>
      <ProgressProvider>
        <Routes>
            <Route index element={<Navigate to="formation" replace />} />
            <Route path="/public" element={<Testing />} />
            <Route path="/public/moteur" element={
              <IsAccessibleRoute>
                < SujetsPreferes />
              </IsAccessibleRoute>
             } />
            <Route path="/conditions_generales" element={<Cgu />} />
            <Route path="/mentions_legales" element={<MentionsL />} />
            <Route
              path="/guidance" 
              element={
                <PrivateRoute> 
                  <Guidance /> 
                </PrivateRoute>
              } 
            />
          <Route path="/public/registration" element={<Registration />} />
          <Route path="/environnement" element={<Environnement />} />
          <Route path="/formation" element={<SearchSystemFormation />} />
          <Route
            path="/saveditems/:userId" 
            element={
              <PrivateRoute> 
                <SavedItems /> 
              </PrivateRoute>
            } 
          />
          <Route path="/metiers" element={
                          // <PrivateRoute>
                            <SearchSystemmetier />
                          // </PrivateRoute>
            } />
          <Route path="/metiers/:professionName/:professionId" element={<MetierDetailPage />} />
          <Route path="/formationdetails" element={<FormationDetailPage />} />
          {/* <Route path="/formationdetails/:formationId" element={<FormationDetailPage />} /> */}
          <Route path="/formation/:schoolName/:formationName/:formationId" element={<FormationDetailPage />} />
          
          <Route path="/igikai" element={<QuiCeQueIkigai />} />
          <Route path="*" element={<ErrorPage />} />

          {/*<Route path="/landing" element={<LandingPage />} />*/}
            <Route
                path="/landing"
                element={
                    <PublicRoute>
                        <LandingPage />
                    </PublicRoute>
                }
            />
        </Routes>
      </ProgressProvider>
    </AuthProvider>
  );
}

export default App;
