import { Link } from "react-router-dom";
import React from "react";
import Insta from "../images/social/insta";
import Fb from "../images/social/fb";
import { Linkedin } from "../images/social/Linkedin";
import { Youtube } from "../images/social/youtube";

export default function FormationSearchFooter() {
    return (
        <div className="text-black font-poppins bg-transparent rounded-lg shadow-lg border-2 p-4">
            <div className="container mx-auto px-4 flex flex-col sm:flex-row items-center justify-between text-center sm:text-left">

                {/* Footer Text Links */}
                <div className="text-sm flex gap-4 text-gray-500 flex-col sm:flex-row sm:items-center">
                    <span>© 2024 Linkyways —</span>
                    <Link to="/mentions_legales" className="text-gray-400 underline hover:text-gray-600">
                        Mentions légales
                    </Link>
                    <Link to="/conditions_generales" className="text-gray-400 underline hover:text-gray-600">
                        Conditions générales
                    </Link>
                </div>

                {/* Social Icons */}

            </div>
        </div>
    );
}
