import { useAxios } from './caller.service';
import { parseUserAgent } from './reference.service';

export const useUserService = () => {
    const AxiosInstance = useAxios();

    let getUserStatus = () => {
        return AxiosInstance.get('/User_Status/');
    }

    let getStudiesLevel = (status = 0, graduate = 0) => {
        let params = {};

        if (status !== 0) {
            params.status = status;
        }

        if (graduate !== 0) {
            params.graduate = graduate;
        }
        // console.log("params ", params)
        return AxiosInstance.get('/studies_level/', { params: params });
    }

    let getBacSpeciality = () => {
        return AxiosInstance.get('/bac_speciality/');
    }

    let getSubjectsCategries = () => {
        return AxiosInstance.get('/subjects_categries/');
    }

    let getBacSpePro = () => {
        return AxiosInstance.get('/bac_spe_pro/');

    }

    let getSalaireRange = () => {
        return AxiosInstance.get('/32_salary_ranges/');

    }
    let getBusinessSector = () => {
        return AxiosInstance.get('/31_business_sector/');

    }

    let getBacSpeTechno = () => {
        return AxiosInstance.get('/bac_spe_techno/');
    }

    let getBacSpeGeneral = () => {
        return AxiosInstance.get('/bac_spe_general/');
    }

    let getBacType = () => {
        return AxiosInstance.get('/bac_type/');
    }

    let get17AreaStudies = () => {
        return AxiosInstance.get('/17_area_studies/');
    }

    let getUserCalculate = (userId) => {
        return AxiosInstance.get(`/user/user_calculate/${userId}`);
    }

    let getUserSubmissionData = (userId) => {
        return AxiosInstance.get(`/GetUserSubmissionData/${userId}`);
    }

    let getUserAiResult = async (user_id) => {
        if (user_id == null) {
            return Promise.reject(new Error("User ID is required"));
        }
        try {
            const reference = parseUserAgent();
            let response = await AxiosInstance.post(`/GetUserResults`, {
                user_id,
                reference
            });
            return response;
        } catch (error) {
            console.error('Error submitting data:', error);
            throw error;
        }
    };

    return {
        getUserStatus,
        getStudiesLevel,
        getBacSpeciality,
        getSubjectsCategries,
        getBacSpePro,
        getUserSubmissionData,
        getBacSpeTechno,
        getBacSpeGeneral,
        getBacType,
        getUserCalculate,
        getBusinessSector,
        get17AreaStudies,
        getSalaireRange,
        getUserAiResult
    }
}