import LayoutNo from "../utils/LayoutNo";
import HeartIcon from "../images/heart.svg";
import React, {useState} from "react";
import LocationPin from "../images/formationIcons/locationPin.svg"
import Website from "../images/formationIcons/website.svg"
import FormationLinearChart from "../utils/FormationLinearChart";
import FormationMap from "../utils/FormationMap";
import {useNavigate, useParams} from 'react-router-dom';
import PresentationDuCours from "../components/FormationComponents/PresentationDuCours";
import {useEffect} from "react";
import {useFormationService} from "../services/formation.service";
import {useAuth} from "../services/auth.provider";
import FilledHeartIcon from "../images/heart-filled.svg";
import {CryptoService} from "../services/crypte.service";
import {Button, notification} from "antd";
import {LeftOutlined} from "@ant-design/icons";


export default function FormationDetailPage(){
    let { formationId } = useParams();
    const [formation,setFormation]=useState({});
    const { getFormationById, savePostBac, deletePostBac } = useFormationService();
    const [loading, setLoading] = useState(true);
    const [isFavorite, setIsFavorite] = useState();
    const [userId, setUserId] = useState(0);

    const navigate = useNavigate();
    const [showRetourButton, setShowRetourButton] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (window.history.length > 1) {
            setShowRetourButton(true);
        }
    }, []);

    const handleRetourClick = () => {
        navigate(-1);
    };


    useEffect(() => {
        const user = localStorage.getItem('user_id');
        if (user) {
            const decryptedId = CryptoService.decryptData(user);
            setUserId(decryptedId);
        }
    }, []);

    useEffect(() => {
        const fetchFormation = async () => {
            try {
                const response = await getFormationById(formationId, userId);
                console.log("details", response.data)
                const formationData = response.data.data[0];
                setFormation(formationData);
                setIsFavorite(formationData.saved);
            } catch (err) {
                console.log("Error fetching formation data:", err);
            } finally {
                setLoading(false);
            }
        };
        fetchFormation();
    }, [formationId, userId,isFavorite]);


    const handleFavoriteToggle = async () => {
        try {
            if (isFavorite) {
                const response = await deletePostBac(userId, formationId);
                if (response.success) {
                    setIsFavorite(false);
                }
            } else {
                const response = await savePostBac(userId, formationId);
                if (response.success) {
                    setIsFavorite(true);
                    notification.success({
                        message: 'Enregistré',
                        placement: 'topRight',
                    });
                }
            }
        } catch (error) {
            console.error("Error toggling favorite status:", error);
        }
    };





    if(loading){
        return (
            <LayoutNo>
            <div className="p-1">
                <div className="animate-pulse   md:mt-2 mt-12">
                    <div className="rounded-md bg-gray-300 h-56 w-full mb-4"></div>
                </div>
                <div className="animate-pulse md:flex md:w-8/12 flex-col    gap-1">
                    <div className="rounded-md bg-gray-300 h-56 w-full mb-4"></div>
                    <div className="rounded-md bg-gray-300 h-56 w-full mb-4"></div>
                </div>
                <div className="animate-pulse  md:w-8/12  gap-1">
                    <div className="rounded-md bg-gray-300 h-96 w-full mb-4"></div>
                </div>
            </div>
            </LayoutNo>
        );
    }

    return(
        <LayoutNo>
            {/*<div className={`p-1 ${!isAuthenticated ? "md:mx-44" : ""}`}>*/}
            <div className="p-1">

            <div className="relative mt-12 md:mt-2 w-full flex flex-col md:flex-row  ">
                <div className="w-full bg-[#E9F2FB] border-1 md:p-4 p-2 rounded-lg ">
                    {showRetourButton && (
                        <Button
                            icon={<LeftOutlined  className={"text-blue-500   text-lg"}/>}
                            onClick={handleRetourClick}
                            className=" absolute p-1 top-1 left-2  shadow-lg bg-white backdrop-blur-lg border-0 rounded-full  "
                            shape="circle"
                        />
                    )}
                    <div>

                        {/* Large Screens Design */}
                        <div className="hidden md:block md:w-8/12 ">

                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <img
                                        src={formation.logo}
                                        alt="Profile"
                                        className="rounded-md mr-4 w-2 "
                                    />
                                    <div className="w-full">
                                        <p className="font-semibold text-[16px] font-poppins text-[#141943] mr-5 ">
                                            {formation.tc}
                                        </p>
                                        <h2 className="font-bold text-[24px] font-inter text-[#141943] mr-5 ">
                                            {formation.nom_formation}
                                        </h2>
                                        <div className="flex justify-between w-full">
                                            <div className="justify-start">
                                                <p className="text-[#434769] font-inter max-w-16rem">
                                                    {formation.nom_etab}
                                                </p>
                                            </div>
                                            <div className="flex items-center justify-end">
                                                <img
                                                    src={Website}
                                                    alt="Poursuite d'études"
                                                    className="mb-3 mr-1"
                                                />
                                                <a
                                                    href={formation.etab_url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-gray-800 underline"
                                                >
                                                    <p className="text-[#434769]  font-inter">
                                                        site web
                                                    </p>
                                                </a>
                                            </div>
                                        </div>


                                        <div className="flex  items-center">
                                            <img
                                                src={LocationPin}
                                                alt="Poursuite d'études"
                                                className="mb-3 mr-1"
                                            />
                                            <p className="text-[#434769] font-inter">
                                                {formation?.code_postal?.split('.')[0] || '--'}{" "}{formation.commune || 'Unknown'}, {formation.region || 'Unknown'}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="justify-end md:mr-5 xl:mr-8">
                                    <button onClick={handleFavoriteToggle}>
                                        <img
                                            src={isFavorite ? FilledHeartIcon : HeartIcon}
                                            alt={isFavorite ? "Remove from favorites" : "Add to favorites"}
                                            className="object-cover min-w-6"
                                        />
                                    </button>

                                </div>
                            </div>
                        </div>

                        {/* Small Screens Design */}
                        <div className="block md:hidden w-full ">
                            <div className="flex flex-col space-y-4 mb-5" >
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center justify-start">
                                        <img
                                            src={formation.logo}
                                            alt="Profile"
                                            className="rounded-md mr-2  w-2"
                                        />
                                        <h2 className="font-semibold text-[16px] font-inter text-[#141943] mr-1 max-w-12rem  line-clamp-2">
                                            {formation.nom_etab}
                                        </h2>
                                    </div>
                                    {/*<button className="justify-end">*/}
                                    {/*    <img*/}
                                    {/*        src={HeartIcon}*/}
                                    {/*        alt="add to favorites"*/}
                                    {/*        className="h-7 mb-1"*/}
                                    {/*    />*/}
                                    {/*</button>*/}
                                    <button onClick={handleFavoriteToggle} className="justify-end">
                                        <img
                                            src={isFavorite ? FilledHeartIcon : HeartIcon}
                                            alt={isFavorite ? "Remove from favorites" : "Add to favorites"}
                                            className="h-7 mb-1"
                                        />
                                    </button>
                                </div>
                                <hr className="border-t-2 mt-2  border-gray-300 " />
                                <div className="w-full">
                                    <p className="font-semibold text-[16px] font-poppins text-[#141943]  ">
                                        {formation.tc}
                                    </p>
                                    <p className="font-bold text-[20px] font-inter text-[#141943] ">
                                        {formation.nom_formation}
                                    </p>
                                    <div className="flex items-center -mt-2">
                                        <img
                                            src={LocationPin}
                                            alt="Poursuite d'études"
                                            className="mr-1 mb-3 inline-block"
                                        />
                                        <p className="text-[#434769] text-[14px] font-inter">
                                            {formation?.code_postal?.split('.')[0] || '--'}{" "}{formation.commune || 'Unknown'}, {formation.region || 'Unknown'}

                                        </p>
                                    </div>
                                    <div className="flex items-center -mt-2">
                                        <img
                                            src={Website}
                                            alt="Poursuite d'études"
                                            className="mr-1 mb-3 inline-block"
                                        />

                                        <p className="text-[#434769] text-[14px] font-inter">
                                            <a
                                                href={formation.etab_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-gray-800 underline"
                                            >
                                                <p className="text-[#434769] mt-2 font-inter">
                                                    site web
                                                </p>
                                            </a>                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <PresentationDuCours
                    niveau={formation.niveau}
                    amenagement={formation.amenagement}
                    apprentissage={formation.apprentissage}
                    duree={formation.duree}
                    fiche={formation.fiche}
                    totalcandidatsformation={formation.effectif_total_des_candidats_pour_une_formation}
                    selectivite={formation.selective}
                    taux_dacces={formation.taux_dacces}
                    capacite={formation.capacite_de_letablissement_par_formation}
                    />
            </div>

            <>
                {formation.effectif_des_admis_neo_bacheliers_generaux > 0 ||
                formation.effectif_des_admis_neo_bacheliers_professionnels > 0 ||
                formation.effectif_des_admis_neo_bacheliers_technologiques > 0 ? (
                    <div>
                    <div className="md:w-8/12 md:mt-2 md:mx-0 mx-2">
                        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 md:mr-4 xl:mr-8">
                            <div className=" border-1   border-[#D0DBE7]  w-full md:w-6/12 shadow-md rounded-md p-3">
                                <FormationLinearChart
                                    effectif_generaux={formation.effectif_des_admis_neo_bacheliers_generaux}
                                    effectif_professionnels={formation.effectif_des_admis_neo_bacheliers_professionnels}
                                    effectif_technologiques={formation.effectif_des_admis_neo_bacheliers_technologiques}/>
                            </div>

                            <div className=" border-1   border-[#D0DBE7]  w-full md:w-6/12 shadow-md rounded-md p-3">
                                <div className=" p-1">
                                    <p className="font-poppins text-[16px] font-semibold text-[#141943]">
                                        Poursuite d'études
                                    </p>
                                    <div className="flex items-center">
                                        {/*<img*/}
                                        {/*    src={Arrow}*/}
                                        {/*    alt="Poursuite d'études"*/}
                                        {/*    className=" mr-1"*/}
                                        {/*/>*/}
                                        {/*<span className="text-[16px] text-[#141943] font-semibold">Licence Physique-Chimie</span>*/}
                                        <span className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="md:w-8/12 mt-3 md:mx-0 mx-2 mb-5">
                            <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 md:mr-4 xl:mr-8">
                                <div className=" border-1   border-[#D0DBE7]  w-full md:w-8/12 shadow-md rounded-md p-3">
                                    <div className=" p-1">
                                        <p className="font-poppins text-[16px] font-semibold text-[#141943]">
                                            Débouchées professionnelles
                                        </p>
                                        <div className="items-center">
                                            <div className="flex items-start " >
                                                {/*<span className="font-inter text-[#141943] text-lg -mt-2">•</span>*/}
                                                {/*<p className="ml-2 font-inter text-[#141943] text-base -mt-2 ">bientôt disponible </p>*/}
                                                <span className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible </span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                ):(

                    <div className="md:w-8/12 md:mt-2 md:mx-0 mx-2">
                        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 md:mr-4 xl:mr-8">
                            <div className=" border-1   border-[#D0DBE7]  w-full md:w-6/12 shadow-md rounded-md p-3">
                                <div className=" p-1">
                                    <p className="font-poppins text-[16px] font-semibold text-[#141943]">
                                        Poursuite d'études
                                    </p>
                                    <div className="flex items-center">
                                        {/*<img*/}
                                        {/*    src={Arrow}*/}
                                        {/*    alt="Poursuite d'études"*/}
                                        {/*    className=" mr-1"*/}
                                        {/*/>*/}
                                        {/*<span className="text-[16px] text-[#141943] font-semibold">Licence Physique-Chimie</span>*/}
                                        <span className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible </span>

                                    </div>
                                </div>
                            </div>

                            <div className=" border-1   border-[#D0DBE7]  w-full md:w-6/12 shadow-md rounded-md p-3">
                                <div className=" p-1">
                                    <p className="font-poppins text-[16px] font-semibold text-[#141943]">
                                        Débouchées professionnelles
                                    </p>
                                    <div className="items-center">
                                        <div className="flex items-start " >
                                            {/*<span className="font-inter text-[#141943] text-lg -mt-2">•</span>*/}
                                            {/*<p className="ml-2 font-inter text-[#141943] text-base -mt-2 ">bientôt disponible </p>*/}
                                            <span className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </>



            <div className="md:w-8/12 mt-3 md:mx-0 mx-2 mb-5">
                <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4 md:mr-4 xl:mr-8">
                    <div className=" border-1   border-[#D0DBE7]  w-full md:w-8/12 shadow-md rounded-md ">
                    <FormationMap gps={formation.gps} nomEtab={formation.nom_etab}/>
                    </div>
                </div>
            </div>
        </div>
        </LayoutNo>


        )
}