
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, notification, Dropdown, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import logo from "../images/logo.svg";
import UserIcon from "../images/iconuser.svg";
import LoginModal from './modal/auth/LoginModal';

export default function LandingNav() {
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [nextRoute, setRouteNextPage] = useState("/formation");

    const showModal = () => {
        setRouteNextPage("/formation");
        setIsModalVisible(true);
    };
    const showModalMetier = () => {
        setRouteNextPage("/metiers");
        setIsModalVisible(true);
    };

    const hideModal = () => setIsModalVisible(false);


    const dropdownMenu1 = (
        <Menu
            items={[
                { label: <Link to="/public/registration" className="font-inter text-sm">S'inscrire</Link>, key: 'register' },
                { type: 'divider', key: 'divider1' },
                {
                    label: (
                        <div onClick={showModal} style={{ cursor: 'pointer' }} className="font-inter text-sm flex items-center">
                            <img src={UserIcon} alt="Login Icon" className="h-5 mr-2" />
                            <span>S'identifier</span>
                        </div>
                    ),
                    key: 'login',
                },
            ]}
        />
    );
    const dropdownMenu = (
        <Menu
            items={[
                { label: <Link to="/metiers" className="font-inter text-sm">Métiers</Link>, key: 'metiers' },
                { label: <Link to="/public" className="font-inter text-sm">Test d'orientation</Link>, key: 'orientation' },
                { label: <Link to="/formation" className="font-inter text-sm">Formations</Link>, key: 'formations' },
                { label: <Link to="/public/registration" className="font-inter text-sm">S'inscrire</Link>, key: 'register' },
                { type: 'divider', key: 'divider1' },
                {
                    label: (
                        <div onClick={showModal} style={{ cursor: 'pointer' }} className="font-inter text-sm flex items-center">
                            <img src={UserIcon} alt="Login Icon" className="h-5 mr-2" />
                            <span>S'identifier</span>
                        </div>
                    ),
                    key: 'login',
                },
            ]}
        />
    );

    return (
        <nav className="bg-white p-2 flex justify-between items-center fixed top-0 left-0 w-full border-b border-gray-300 z-50">
            <div>
                <Link to="/landing">
                    <img
                        alt="logo"
                        src={logo}
                        width="170"
                        className="pl-3 cursor-pointer"
                    />
                </Link>
            </div>

            <div className="hidden md:flex items-center">
                {location.pathname === '/landing' && (
                    <>
                        <Link to="/public" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                            Test d'orientation
                        </Link>
                        <Link to="/metiers" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                            Métiers
                        </Link>
                        <Link to={"/formation"} className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                            Formations
                        </Link>
                        <Link to="/public/registration" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                            S'inscrire
                        </Link>
                        <Button
                            type="default"
                            className="flex items-center border border-gray-300 px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold"
                            onClick={showModal}
                        >
                            <span>S'identifier</span>
                            <img
                                src={UserIcon}
                                alt="Login Icon"
                                className="ml-2 h-5 w-5"
                            />
                        </Button>
                    </>
                )}
                {location.pathname !== '/landing' && (
                    <>
                        <Link to="/public/registration" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                            S'inscrire
                        </Link>
                        <Button
                            type="default"
                            className="flex items-center border border-gray-300 px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold"
                            onClick={showModal}
                        >
                            <span>S'identifier</span>
                            <img
                                src={UserIcon}
                                alt="Login Icon"
                                className="ml-2 h-5 w-5"
                            />
                        </Button>
                    </> )}
            </div>

            {location.pathname === '/landing' && (
                <>
                    <div className="md:hidden flex justify-end">
                        <Dropdown overlay={dropdownMenu} trigger={['click']} placement="bottomRight">
                            <Button
                                type="default"
                                icon={<MenuOutlined />}
                            />
                        </Dropdown>

                    </div>
                </>

            )}
            {location.pathname !== '/landing' && (
                <>
                    <div className="md:hidden flex justify-end">
                        <Dropdown overlay={dropdownMenu1} trigger={['click']} placement="bottomRight">
                            <Button
                                type="default"
                                icon={<MenuOutlined />}
                            />
                        </Dropdown>

                    </div>
                </>
            )}
            <LoginModal visible={isModalVisible} onHide={hideModal} toRoute={nextRoute} />
        </nav>
    );
}



// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { Button, notification, Dropdown, Menu } from 'antd';
// import { MenuOutlined } from '@ant-design/icons';
// import logo from "../images/logo.svg";
// import UserIcon from "../images/iconuser.svg";
// import LoginModal from './modal/auth/LoginModal';
//
// export default function LandingNav() {
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [nextRoute, setRouteNextPage] = useState("/formation")
//
//     const showModal = () => {
//         setRouteNextPage("/formation")
//         setIsModalVisible(true);
//     };
//     const showModalMetier = () => {
//         setRouteNextPage("/metiers")
//         setIsModalVisible(true);
//     };
//
//     const hideModal = () => setIsModalVisible(false);
//
//     const showPopup = () => {
//         notification.info({
//             message: 'Bientôt Disponible',
//             description: 'Cette fonctionnalité sera bientôt disponible.',
//             placement: 'top',
//         });
//     };
//
//     const dropdownMenu = (
//         <Menu
//             items={[
//                 { label: <Link to="/metiers" className="font-inter text-sm">Métiers</Link>, key: 'metiers' },
//                 { label: <Link to="/public" className="font-inter text-sm">Test d'orientation</Link>, key: 'orientation' },
//                 { label: <Link onClick={showPopup} className="font-inter text-sm">Formations</Link>, key: 'formations' },
//                 { label: <Link to="/public/registration" className="font-inter text-sm">S'inscrire</Link>, key: 'register' },
//                 { type: 'divider', key: 'divider1' },
//                 {
//                     label: (
//                         <div onClick={showModal} style={{ cursor: 'pointer' }} className="font-inter text-sm flex items-center">
//                             <img src={UserIcon} alt="Login Icon" className="h-5 mr-2" />
//                             <span>S'identifier</span>
//                         </div>
//                     ),
//                     key: 'login',
//                 },
//             ]}
//         />
//     );
//
//     return (
//         <nav className="bg-white p-2 flex justify-between items-center fixed top-0 left-0 w-full border-b border-gray-300 z-50">
//             <div>
//                 <Link to="/landing">
//                     <img
//                         alt="logo"
//                         src={logo}
//                         width="170"
//                         className="pl-3 cursor-pointer"
//                     />
//                 </Link>
//             </div>
//
//             {/* Large screens: Buttons are shown normally */}
//             <div className="hidden md:flex items-center">
//                 <Link to="/public" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
//                     Test d'orientation
//                 </Link>
//                 <Link to="/metiers" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
//                     Métiers
//                 </Link>
//                 {/*<button className="px-4 py-2 font-poppins text-[#141943] font-semibold"*/}
//                 {/*onClick={showModalMetier}*/}
//                 {/*>*/}
//                 {/*    */}
//                 {/*</button>*/}
//
//                 <Link to={"/formation"} className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
//                     Formations
//                 </Link>
//                 <Link to="/public/registration" className="px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
//                     S'inscrire
//                 </Link>
//                 <Button
//                     type="default"
//                     className="flex items-center border border-gray-300 px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold"
//                     onClick={showModal}
//                 >
//                     <span>S'identifier</span>
//                     <img
//                         src={UserIcon}
//                         alt="Login Icon"
//                         className="ml-2 h-5 w-5"
//                     />
//                 </Button>
//             </div>
//
//             {/* Small screens: Dropdown icon */}
//             <div className="md:hidden flex justify-end">
//                 <Dropdown overlay={dropdownMenu} trigger={['click']} placement="bottomRight">
//                     <Button
//                         type="default"
//                         icon={<MenuOutlined />}
//                     >
//                    </Button>
//                 </Dropdown>
//             </div>
//             <LoginModal visible={isModalVisible} onHide={hideModal} toRoute={nextRoute} />
//         </nav>
//     );
// }
