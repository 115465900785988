import React, { useState, useEffect } from 'react';
import CountryCodes from '../../utils/json/CountryCodes.json';
import { Dropdown } from 'primereact/dropdown';
import phoneRegexPatterns from '../../utils/json/phoneRegexPatterns.json';
import { Row, Col, Input, Select } from 'antd';

const { Option } = Select;

export default function CloseToFinishStep({ setUserData, data, errors }) {
    const [selectedCountry, setSelectedCountry] = useState('France');
    const [phone_number, setPhoneNumber] = useState('0');
    const [phone, setPhone] = useState('');
    const [user_year_of_birth, setSelectedYear] = useState('');
    const [user_name, setName] = useState('');
    const [user_surname, setSurname] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const years = Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - 10) - i);

    const countries = Array.isArray(CountryCodes) ? CountryCodes.map(({ name, dial_code, code }) => ({
        label: name,
        value: code,
        dialCode: dial_code,
        code: code
    })) : [];

    useEffect(() => {
        const savedData = localStorage.getItem('stepData');
        if (savedData) {
            const parsedData = JSON.parse(savedData).data;
            const stepData = parsedData['6'];
            if (stepData) {
                setSelectedCountry(stepData.country || 'France');
                setPhoneNumber(stepData.phone_number || '');
                setPhone(formatPhoneNumberForCountry(stepData.phone_number, stepData.country));

                setSelectedYear(stepData.user_year_of_birth || '');
                setName(stepData.user_name || '');
                setSurname(stepData.user_surname || '');
                setPostalCode(stepData.postalCode || '');
            }
        }
    }, []);

    const formatPhoneNumberForCountry = (phoneNumber, countryCode) => {
        const dialCode = CountryCodes.find(country => country.name === countryCode)?.dial_code;
        if (dialCode) {
            return phoneNumber.replace(new RegExp(`^\\+${dialCode}`), '0')
        }
        return phoneNumber;
    };

    useEffect(() => {
        const updatedData = {
            country: selectedCountry,
            phone_number: phone_number || null,
            user_year_of_birth: user_year_of_birth,
            user_name: user_name,
            user_surname: user_surname,
            postalCode: postalCode || null,
        };

        if (JSON.stringify(data) !== JSON.stringify(updatedData)) {
            setUserData(updatedData);
        }
    }, [selectedCountry, phone_number, user_year_of_birth, user_name, user_surname, postalCode, data, setUserData]);


    const handleCountryChange = (e) => {
        setSelectedCountry(e.value);
    };

    const handlePhoneChange = (e) => {
        const country = CountryCodes.find(country => country.name === selectedCountry);
        const value = e.target.value;
        setPhone(value)
        if (country) {
            const updatedPhoneNumber = value.replace(/^0/, '').replace(/^\+\d+/, '');
            validatePhoneNumber(country.code, `${country.dial_code}${updatedPhoneNumber}`);
            setPhoneNumber(`${country.dial_code}${updatedPhoneNumber}`);
        }

    };

    const validatePhoneNumber = (countryCode, phoneNumber) => {
        const regexPattern = phoneRegexPatterns[countryCode] || '';
        const isValid = new RegExp(regexPattern).test(phoneNumber);
        if (!isValid) {
            setPhoneError('Numéro de téléphone invalide pour le pays sélectionné');
        } else {
            setPhoneError('');
        }
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };


    const countryTemplate = (option) => {
        return (
            <div className="flex items-center">
                <img
                    alt={option.label}
                    src={`https://flagcdn.com/48x36/${option.value.toLowerCase()}.png`}
                    style={{ width: '18px', marginRight: '10px' }}
                />
                {option.label}
            </div>
        );
    };

    return (
        <div className="mt-5">
            <p className="font-bold text-2xl mb-2">Presque terminé!</p>
            <div className="rounded-md mx-auto px-2 py-3 max-w-3xl">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Nom*</label>
                        <input
                            type="text"
                            placeholder={errors.user_name ? errors.user_name  : "Nom"}
                            value={user_name}
                            onChange={(e) => setName(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.user_name ? 'border placeholder-red-500' : ''}`}
                        />
                        {/*{errors.user_name && <p className="text-red-500 text-sm">{errors.user_name}</p>}*/}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Prénom*</label>
                        <input
                            type="text"
                            placeholder={errors.user_surname ? errors.user_surname  : "Prénom"}
                            value={user_surname}
                            onChange={(e) => setSurname(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.user_surname ? 'border placeholder-red-500' : ''}`}

                        />
                        {/*{errors.user_surname && <p className="text-red-500 text-sm">{errors.user_surname}</p>}*/}
                    </div>
                    {/*<div className="flex flex-col">*/}
                    {/*    <label className="block text-sm font-inter text-[#141943E5] mb-2">Code postal*</label>*/}
                    {/*    <input*/}
                    {/*        type="number"*/}
                    {/*        placeholder={errors.postalCode ? errors.postalCode : "Code postal"}*/}
                    {/*        value={postalCode}*/}
                    {/*        onChange={(e) => setPostalCode(e.target.value)}*/}
                    {/*        className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.postalCode ? 'border  placeholder-red-500' : ''}`}*/}
                    {/*    />*/}
                    {/*    /!*{errors.postalCode && <p className="text-red-500 text-sm">{errors.postalCode}</p>}*!/*/}
                    {/*</div>*/}
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Année de naissance*</label>
                        <select
                            value={user_year_of_birth || ''}
                            onChange={handleYearChange}
                            className={`bg-white border border-[#D0DBE7] rounded-md w-full p-3 ${errors.user_year_of_birth ? 'border-red-500' : ''}`}
                        >
                            <option value="" disabled>Sélectionnez l'année</option>
                            {years.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {errors.user_year_of_birth && <p className="text-red-500 text-sm">{errors.user_year_of_birth}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-medium text-[#141943E5] mb-2">Pays*</label>
                        <Dropdown
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            options={countries}
                            optionLabel="label"
                            optionValue="label"
                            placeholder="Sélectionnez un pays"
                            itemTemplate={countryTemplate}
                            className={`bg-white border border-[#D0DBE7] rounded-md w-full p-2 ${errors.country ? 'border-red-500' : ''}`}
                            //showClear
                        />
                        {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
                    </div>


                    {/*<div className="flex flex-col">*/}
                    {/*    <label className="block text-sm font-inter text-[#141943E5] mb-2">Téléphone</label>*/}
                    {/*    <input*/}
                    {/*        type="number"*/}
                    {/*        value={phone}*/}
                    {/*        onChange={handlePhoneChange}*/}
                    {/*        placeholder="Numéro de téléphone"*/}
                    {/*        className={`bg-[#F2F2F2] p-3 rounded-md w-full ${phoneError ? 'border border-red-500' : ''}`}*/}
                    {/*    />*/}
                    {/*    {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
    // return (
    //     <div className="mt-5 px-2">
    //         <p className="font-bold text-2xl mb-2">Presque terminé!</p>
    //         <div className="rounded-md mx-auto px-2 py-3 max-w-3xl">
    //             <Row gutter={[16, 16]}>
    //                 <Col xs={24} md={12}>
    //                     <label className="block text-sm font-inter text-[#141943E5] mb-2">Nom*</label>
    //                     <Input
    //                         type="text"
    //                         placeholder={errors.user_name ? errors.user_name : "Nom"}
    //                         value={user_name}
    //                         onChange={(e) => setName(e.target.value)}
    //                         className={errors.user_name ? 'border-red-500' : ''}
    //                     />
    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <label className="block text-sm font-inter text-[#141943E5] mb-2">Prénom*</label>
    //                     <Input
    //                         type="text"
    //                         placeholder={errors.user_surname ? errors.user_surname : "Prénom"}
    //                         value={user_surname}
    //                         onChange={(e) => setSurname(e.target.value)}
    //                         className={errors.user_surname ? 'border-red-500' : ''}
    //                     />
    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <label className="block text-sm font-inter text-[#141943E5] mb-2">Code postal*</label>
    //                     <Input
    //                         type="number"
    //                         placeholder={errors.postalCode ? errors.postalCode : "Code postal"}
    //                         value={postalCode}
    //                         onChange={(e) => setPostalCode(e.target.value)}
    //                         className={errors.postalCode ? 'border-red-500' : ''}
    //                     />
    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <label className="block text-sm font-medium text-[#141943E5] mb-2">Pays*</label>
    //                     <Dropdown
    //                         value={selectedCountry}
    //                         onChange={handleCountryChange}
    //                         options={countries}
    //                         optionLabel="label"
    //                         optionValue="label"
    //                         placeholder="Sélectionnez un pays"
    //                         itemTemplate={countryTemplate}
    //                         className={`bg-white border border-[#D0DBE7] rounded-md w-full ${errors.country ? 'border-red-500' : ''}`}
    //                         //showClear
    //                     />
    //                     {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <label className="block text-sm font-inter text-[#141943E5] mb-2">Année de naissance*</label>
    //                     <Select
    //                         value={user_year_of_birth || ''}
    //                         onChange={handleYearChange}
    //                         placeholder="Sélectionnez l'année"
    //                         className={errors.user_year_of_birth ? 'border-red-500 w-full' : 'w-full'}
    //                     >
    //                         {years.map((year) => (
    //                             <Option key={year} value={year}>
    //                                 {year}
    //                             </Option>
    //                         ))}
    //                     </Select>
    //                     {errors.user_year_of_birth && <p className="text-red-500 text-sm">{errors.user_year_of_birth}</p>}
    //                 </Col>
    //                 <Col xs={24} md={12}>
    //                     <label className="block text-sm font-inter text-[#141943E5] mb-2">Téléphone</label>
    //                     <Input
    //                         type="number"
    //                         value={phone}
    //                         onChange={handlePhoneChange}
    //                         placeholder="Numéro de téléphone"
    //                         className={phoneError ? 'border-red-500' : ''}
    //                     />
    //                     {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
    //                 </Col>
    //             </Row>
    //         </div>
    //     </div>
    // );
    
}
