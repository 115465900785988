import { useAxios } from './caller.service';

export const useFormationService = () => {
    const AxiosInstance = useAxios();

    let getAreaStudies = () => {
        return AxiosInstance.get('/17_area_studies/');
    }
    let getAccessRate = () => {
        return AxiosInstance.get('/23_accessrate_range/');
    }
    let getTrainingType = () => {
        return AxiosInstance.get('/7e_training_type/');
    }
    //
    // const getSearchPostBac = async (userId, page, keyword, apprenticeship, distenciel,pub,trainingType, areaStudies, accessRateRange,location) => {
    //     let query = `/21_postbac/?user_id=${userId}&page_number=${page}`;
    //
    //     if (keyword) {
    //         query += `&keyword=${keyword}`;
    //     }
    //
    //     if (apprenticeship !== undefined) {
    //         query += `&apprenticeship=${apprenticeship}`;
    //     }
    //
    //     if (distenciel !== undefined) {
    //         query += `&distenciel=${distenciel}`;
    //     }
    //     if (pub !== undefined) {
    //         query += `&public=${pub}`;
    //     }
    //     if (trainingType) query += `&training_type=${trainingType}`;
    //     if (areaStudies) query += `&area_studies=${areaStudies}`;
    //     if (accessRateRange) query += `&accessrate_range=${accessRateRange}`;
    //     if (location) query += `&location=${location}`;
    //
    //     try {
    //         const response = await AxiosInstance.get(query);
    //         if (response.data.detail === "No Data Found") {
    //             return { success: false, message: "No data found.", data: [] };
    //         } else if (response.data.status === "success" && response.data.data) {
    //             const { total_count, total_pages, results } = response.data.data;
    //             return {
    //                 success: true,
    //                 message: "Data fetched successfully.",
    //                 data: {
    //                     total_count,
    //                     total_pages,
    //                     results
    //                 }
    //             };
    //         } else {
    //             return { success: false, message: "Unexpected response structure.", data: [] };
    //         }
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //         return { success: false, message: "Error fetching data.", data: [] };
    //     }
    // };
    //

    const getSearchPostBac = async (userId, page, keyword, apprenticeship, distenciel, pub, trainingType, areaStudies, accessRateRange, location) => {
        let query = `/21_postbac/?user_id=${userId}&page_number=${page}`;

        if (keyword) {
            query += `&keyword=${keyword}`;
        }

        if (apprenticeship !== undefined && apprenticeship !== "") {
            query += `&apprenticeship=${apprenticeship}`;
        }

        if (distenciel !== undefined && distenciel !== "") {
            query += `&distenciel=${distenciel}`;
        }

        if (pub !== undefined && pub !== "") {
            query += `&public=${pub}`;
        }

        if (trainingType) {
            query += `&training_type=${trainingType}`;
        }

        if (areaStudies) {
            query += `&area_studies=${areaStudies}`;
        }

        if (accessRateRange) {
            query += `&accessrate_range=${accessRateRange}`;
        }

        if (location) {
            query += `&location=${location}`;
        }

        try {
            const response = await AxiosInstance.get(query);
            if (response.data.detail === "No Data Found") {
                return { success: false, message: "No data found.", data: [] };
            } else if (response.data.status === "success" && response.data.data) {
                const { total_count, total_pages, results } = response.data.data;
                return {
                    success: true,
                    message: "Data fetched successfully.",
                    data: {
                        total_count,
                        total_pages,
                        results
                    }
                };
            } else {
                return { success: false, message: "Unexpected response structure.", data: [] };
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, message: "Error fetching data.", data: [] };
        }
    };

    const getPublicPostBac = async () => {
        const query = `/getfirst_postbac_public/`;

        try {
            const response = await AxiosInstance.get(query);
            if (response.data.detail === "No Data Found") {
                return { success: false, message: "No data found.", data: [] };
            } else if (response.data.status === "success" && response.data.data) {
                const { results } = response.data.data;
                return {
                    success: true,
                    message: "Data fetched successfully.",
                    data: {
                        results
                    }
                };
            } else {
                return { success: false, message: "Unexpected response structure.", data: [] };
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return { success: false, message: "Error fetching data.", data: [] };
        }
    };


    let getFormationById = (formationId,userId) => {
        return AxiosInstance.get(`/21_postbac_a/?postbac_id=${formationId}&user_id=${userId}`);
    };

    const savePostBac = async (userId, postbacId) => {
        try {
            const response = await AxiosInstance.post('/save_postbac', {
                user_id: userId,
                postbac_id: postbacId
            });
            if (response.data.status === "success") {
                return { success: true, message: "Postbac saved successfully.", data: response.data };
            } else {
                return { success: false, message: "Failed to save postbac.", data: [] };
            }
        } catch (error) {
            console.error("Error saving postbac:", error);
            return { success: false, message: "Error saving postbac.", data: [] };
        }
    };
    const deletePostBac = async (userId, postbacId) => {
        try {
            const response = await AxiosInstance.delete(`/delete_postbac/${userId}`, {
                params: { postbac_id: postbacId }
            });
            if (response.data.status === "success") {
                return { success: true, message: "Postbac removed from favorites.", data: response.data };
            } else {
                return { success: false, message: "Failed to remove postbac from favorites.", data: [] };
            }
        } catch (error) {
            console.error("Error deleting postbac:", error);
            return { success: false, message: "Error deleting postbac.", data: [] };
        }
    };

    const getSavedPostbac = async (userId) => {
        try {
            const response = await AxiosInstance.get('/get_saved_postbac', {
                params: { user_id: userId }
            });
            if (response.data.status === "success") {
                return { success: true, data: response.data.data };
            } else {
                return { success: false, message: "Failed to fetch saved postbac.", data: [] };
            }
        } catch (error) {
            console.error("Error fetching saved postbac:", error);
            return { success: false, message: "Error fetching saved postbac.", data: [] };
        }
    };

    return {
        getAreaStudies,
        getAccessRate,
        getTrainingType,
        getSearchPostBac,
        getFormationById,
        savePostBac,
        deletePostBac,
        getSavedPostbac,
        getPublicPostBac

    }
}
